import { ActionButton } from "@components/buttons"
import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import AndroidIcon from "~/assets/Android.svg"
import IosIcon from "~/assets/BlackIos.svg"
import BluePlus from "~/assets/BluePlus.svg"
import Check from "~/assets/Check.svg"
import FiveStars from "~/assets/FiveStars.png"
import { Popup } from "~/components/layouts/popup"
import { Block } from "~/components2/atoms/Block"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { SortBox, SubscriptionValueDTO } from "~/services/config/config-dto-types"
import { dConfigService } from "~/services/config/config-service"

import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { formatToDailyAmount } from "~/utils/formaters"
import { getLocalizedPlanName } from "~/utils/localize"
import { usePaywall } from "../../use-paywall"
import { PrePaywall } from "../atoms/prepaywall"
import { PrePaywallTime } from "../atoms/prepaywall-time"
import { Promocode } from "../promocode"
import {
	Wrapper,
	ContentWrapper,
	Content,
	ExpandedArea,
	MediumBlock,
	charlieBenefits,
	PaywallFooter,
	TryTitle,
	MarketsSimple,
	MarketBubble,
} from "../styles"
import { PaywallProps } from "../types"
import RecommendsGlobe from "./assets/recommends.png"
import ReviewIcon from "./assets/review.png"
import { FooterText } from "./components/FooterText"
import { PaymentPopup } from "./components/PaymentPopup/payment-popup"
import { PlanItem } from "./components/PlanItem"
import { reviews, reviewsMan } from "./reviews"
import { EchoUserReviews, PriceText } from "./styles"

export function NepalPaywall({ onNext }: PaywallProps) {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)
	const onboardingType = useQueryParams().get("onboardingType")
	const [allProducts, setAllProducts] = useState<SortBox<SubscriptionValueDTO>[]>([])
	const [selectedProduct, setSelectedProduct] = useState<SortBox<SubscriptionValueDTO> | null>(null)

	const windowSize = useWindowDimensions()

	const [reviewsHidden, setReviewsHidden] = useState(true)
	const toggleReviews = useCallback(() => {
		setReviewsHidden((v) => !v)
	}, [])
	const shownReviews = useMemo(() => {
		const reviewsByOnboardingType = onboardingType === "man" ? reviewsMan : reviews

		return reviewsHidden ? reviewsByOnboardingType.slice(0, 3) : reviewsByOnboardingType
	}, [reviewsHidden, onboardingType])

	const { product, paywallPrice, agreed, setAgreed, timeLeft, visibleTimer, handleScroll, webPrepaywallType } =
		usePaywall(DPaywallType.Nepal, addressZipRef, timeRef, selectedProduct?.value)

	useEffect(() => {
		dConfigService.fetchWebSubscriptions().then((value) => {
			const filteredSubs = value.filter((p) => p.value.paywall === DPaywallType.Nepal)
			const popular = filteredSubs.find((sub) => sub.value.plan?.popular)
			setAllProducts(filteredSubs)
			setSelectedProduct(popular ?? filteredSubs[0])
		})
	}, [])

	useEffect(() => {
		if (timerRef.current) {
			if (visibleTimer) {
				timerRef.current.style.top = "12px"
			} else {
				timerRef.current.style.top = "-40px"
			}
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	defineMessage({ message: "Paying..." })
	defineMessage({ message: "View more" })
	defineMessage({ message: "Show less" })
	defineMessage({ message: "Day" })
	defineMessage({ message: "Days" })

	shownReviews.forEach((r) => {
		defineMessage({ message: r.name })
		defineMessage({ message: r.message })
	})

	const onSelectProduct = (p: any) => {
		setSelectedProduct(p)
	}

	const onOpenPopup = () => {
		Popup.openContent(<PaymentPopup product={selectedProduct!} />)
	}

	const { amount, days, name } = selectedProduct?.value?.plan ?? { amount: 0, days: 0, name: "" }
	const dailyPrice = formatToDailyAmount(amount, days)

	const extendedText = (() => {
		switch (name) {
			case "7-Day":
				return <Trans>weekly</Trans>
			case "1-Month":
				return <Trans>monthly</Trans>
			case "3-Month":
				return <Trans>each 3 months</Trans>
			default:
				return <Trans>monthly</Trans>
		}
	})()

	const oldPrice = (selectedProduct?.value.oldPrice ?? 0) / 100

	const priceText = (
		<>
			<Trans>Extended</Trans> {extendedText} <Trans>after</Trans> {name && getLocalizedPlanName(name)}{" "}
			<Trans>intro offer at the full price of</Trans> ${oldPrice}
		</>
	)

	const btnDisabled = selectedProduct === null

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						hideLoading
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						setAgreed={setAgreed}
						paywallPrice={paywallPrice!}
						name={name}
					/>
					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<PrePaywallTime
									showDownText={false}
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									variant="secondary"
									ref={timeRef}
									timeLeft={timeLeft}
									discountPercent={30}
								/>
								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try your</Trans>{" "}
									<Colored color="">
										<Trans>Glowbe Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List align="left" icon={Check} items={charlieBenefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<Flexed direction="column" gap={16} style={{ marginBottom: 24, marginTop: 32 }}>
										{allProducts.map((product, index) => (
											<PlanItem
												key={index}
												isPopular={Boolean(product?.value.plan!.popular)}
												selected={product === selectedProduct}
												product={product}
												onClick={onSelectProduct}
											/>
										))}
									</Flexed>

									<ActionButton disabled={btnDisabled} isStretch onClick={onOpenPopup}>
										<Trans>Start Glowbe Course</Trans>
									</ActionButton>

									<PriceText>{priceText}</PriceText>

									<Promocode
										closed={({ openPromocode }) => (
											<TextWithIcon
												color="#5856D6"
												margin="24px 0 40px"
												icon={BluePlus}
												centered
												style={{ whiteSpace: "nowrap" }}
												onClick={openPromocode}
											>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<EchoUserReviews>
										<div className="title">
											<Trans>User reviews</Trans>
										</div>
										<div className="reviews">
											{shownReviews.map((r) => (
												<div key={r.name} className="review">
													<div className="row">
														<img className="avatar" src={r.avatar} alt={r.name} />
														<div className="col">
															<div className="name">
																{r.name}
																<img src={ReviewIcon} alt="" />
															</div>
															<div className="recommends">
																<img src={RecommendsGlobe} alt="" />
																<span>
																	<Trans>recommends</Trans>{" "}
																	<span className="power">
																		<Trans>Glowbe</Trans>
																	</span>
																</span>
															</div>
														</div>
													</div>
													<div className="message">{i18nCustom._(r.message)}</div>
												</div>
											))}
										</div>
										<button className="view-more" onClick={toggleReviews}>
											{reviewsHidden ? i18nCustom._("View more") : i18nCustom._("Show less")}
										</button>
									</EchoUserReviews>

									<ActionButton disabled={btnDisabled} isStretch onClick={onOpenPopup}>
										<Trans>Start Glowbe Course</Trans>
									</ActionButton>

									<PriceText>{priceText}</PriceText>

									<MarketsSimple style={{ marginTop: 40 }}>
										<div>
											<span>
												<img alt="" src={AndroidIcon} />
												<p>
													<Trans>Google Play</Trans>
												</p>
											</span>
											<span>
												<img alt="" src={IosIcon} />
												<p>
													<Trans>App Store</Trans>
												</p>
											</span>
										</div>
										<div>
											<MarketBubble>
												<img alt="" src={FiveStars} />
												<p>
													4.8<span>/5</span>
												</p>
											</MarketBubble>
										</div>
										<div>
											<MarketBubble>
												<p>
													1M+{" "}
													<span>
														<Trans>downloads</Trans>
													</span>
												</p>
											</MarketBubble>
											<MarketBubble>
												<p>
													12K{" "}
													<span>
														<Trans>5-star ratings</Trans>
													</span>
												</p>
											</MarketBubble>
										</div>
									</MarketsSimple>
									<FooterText name={getLocalizedPlanName(name)} dailyPrice={dailyPrice} oldPrice={oldPrice} />
								</MediumBlock>

								<PaywallFooter>
									<p>
										{/* Copyright ® 2019-2022 Glowbe. */}
										<Trans>Copyright</Trans> ® 2023 Glowbe.
										<br />
										<Trans>All Rights Reserved</Trans>
									</p>
								</PaywallFooter>
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
