import { OnboardingQuestionRecord } from "~/contexts/questions-context/questions-types"
import { UserPreferences } from "~/subscriptions-web/context/preferences-context/user-preferences"
import {
	SelectScreen,
	SelectPictureScreen,
	SelectWithPictureScreen,
	ChecklistScreen,
	ChecklistPictureScreen,
	ChecklistWithPictureScreen,
	EmailScreenContainer,
	FinalLoaderScreen,
	QuoteListScreen,
	QuoteScreen,
} from "~/views/onboarding/screens"

const LOG = (msg: string) => console.log(msg)

export interface IComponentProps {
	questions: OnboardingQuestionRecord[]
	step: number
	isCurrent: boolean
	currentStep: number
	isSaving: boolean
	moveForward: (props?: { skip?: number, option?: string, options?: string[] }) => void
	preferences: UserPreferences
}

export const CurrentStep = ({
	questions,
	step = 0,
	isCurrent,
	currentStep,
	moveForward,
}: // preferences,
IComponentProps) => {
	if (!questions || questions.length === 0) {
		//LOG("ALERT: There is no questions!")
		return null
	}
	if (step >= questions.length) {
		//LOG("ALERT: step is bigger the number of questions!")
		return null
	}
	if (!step) {
		step = 0
	}

	const question = questions[step]
	//console.log("Q", question)
	switch (question.type) {
		case "select":
			return <SelectScreen key={currentStep} label={question.label} onNext={moveForward} options={question.options!} />
		case "select_pictures":
			return (
				<SelectPictureScreen
					key={currentStep}
					label={question.label}
					onNext={moveForward}
					options={question.options!}
					attachments={question.attachments!}
				/>
			)
		case "select_with_picture":
			return (
				<SelectWithPictureScreen
					key={currentStep}
					label={question.label}
					options={question.options!}
					onNext={moveForward}
					imageSrc={question.attachments![0]}
				/>
			)
		case "jump_select_with_picture":
			return (
				<SelectWithPictureScreen
					key={currentStep}
					label={question.label}
					options={question.options!}
					onNext={moveForward}
					imageSrc={question.attachments![0]}
				/>
			)
		case "checklist_with_picture":
			return (
				<ChecklistWithPictureScreen
					key={currentStep}
					id={question.id}
					question={question.label}
					options={question.options!}
					imageSrc={question.attachments![0]}
					onNext={moveForward}
				/>
			)
		case "checklist":
			return (
				<ChecklistScreen
					key={currentStep}
					id={question.id}
					label={question.label}
					options={question.options!}
					onNext={moveForward}
				/>
			)
		case "checklist_picture":
			return (
				<ChecklistPictureScreen
					key={currentStep}
					id={question.id}
					label={question.label}
					options={question.options!}
					attachments={question.attachments!}
					onNext={moveForward}
				/>
			)
		case "email":
			return (
				<EmailScreenContainer
					key={currentStep}
					onNext={(email: string) => moveForward()}
					label={question.label_2!}
					description={question.label!}
				/>
			)
		case "quote_screen":
			return (
				<QuoteScreen
					key={currentStep}
					onNext={moveForward}
					background={question.attachments![0]}
					label={question.label}
					author={question.label_2!}
					isShown={isCurrent}
				/>
			)
		case "quote_list_screen":
			return (
				<QuoteListScreen
					key={currentStep}
					onNext={moveForward}
					background={question.attachments![0]}
					label={question.label}
					title2={question.label_2!}
					quote1={question.options![0]}
					author1={question.options![1]}
					quote2={question.options![2]}
					author2={question.options![3]}
					isShown={isCurrent}
				/>
			)
		case "finish_loader_checklist":
			return (
				<FinalLoaderScreen
					key={currentStep}
					onNext={moveForward}
					label={question.label}
					items={question.options!.slice(2)}
					readyForAnimation={isCurrent}
					reviewTitle={question.label_2!}
					reviewCreated={question.options![0]}
					reviewAuthor={question.options![1]}
					reviewText={question.label_3!}
				/>
			)
		default:
			return null
	}
}
